<template>
  <div class="select-active-coach-list">
    <draggable-dynamic-table ref="selectActiveCoachesList"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="true"
                             @column:select="selectedRows = $event, checkStatus($event)"
                             @filter:set="setFilters($event)"
                             @filter:remove="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getUsers()"/>


    <vs-button id="selectUsers" class="useral-action-button" @click="$emit('selected', selectedRows)"/>
  </div>
</template>

<script>
  import {getActiveCoaches, getCoaches} from "../../../../http/requests/coaches";

  export default {
    name: 'selectActiveCoaches',
    data() {
      return {
        checkStatusTimer: 0,
        selectedRows: [],
        insertPromptStatus: false,
        loadingTimer: 0,
        selectedCashBoxes: [],
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'price',
            i18n: 'coaches.table.header.reservePrice',
            width: 'calc((100% / 4))',
            minWidth: 150,
            sortable: true,
            // locked: true,
            filter: true,
            filterTypes: [
              {
                icon: 'equals',
                name: 'برابر باشد',
                i18n: 'draggableTable.filter.types.equals',
                id: 1
              },
              {
                icon: 'not-equal',
                name: 'برابر نباشد',
                i18n: 'draggableTable.filter.types.notEqual',
                id: 2
              },
              {
                icon: 'less-than-equal',
                name: 'کوچکتر مساوی',
                i18n: 'draggableTable.filter.types.lessThanEquals',
                id: 4
              },
              {
                icon: 'greater-than-equal',
                name: 'بزرگتر مساوی',
                i18n:
                  'draggableTable.filter.types.greaterThanEquals',
                id: 3
              }
            ],
          },
          {
            field: 'phoneNumber',
            i18n: 'coaches.table.header.phoneNumber',
            width: 'calc((100% / 4))',
            minWidth: 150,
            align: 'center',
            sortable: true,
            // locked: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'name',
            i18n: 'coaches.table.header.name',
            width: 'calc((100% / 4) * 2)',
            minWidth: 200,
            sortable: true,
            // locked: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'row',
            i18n: 'coaches.table.header.row',
            width: '50px',
            minWidth: 50,
            align: 'center',
            // locked: true,
            // sortable: true,
            footer: {
              /*type: 'auto-counter'*/
            }
          },
          {
            field: 'select',
            i18n: 'users.table.header.select',
            width: '50px',
            minWidth: 50,
            align: 'center',
            selectable: true,
            multiSelect: this.multiSelect,
            locked: true
          }
        ],
        data: [],
        page: 1,
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        endedList: false
      }
    },
    props: {
      multiSelect: {
        type: Boolean,
        default: () => {
          return true
        }
      },
      selectedTimes: {
        type: Array,
        default: () => { return [] }
      }
    },
    created() {
      this.getUsers()
    },
    methods: {
      checkStatus(selected) {
        if (!this.multiSelect) {
          clearTimeout(this.checkStatusTimer)
          this.checkStatusTimer = setTimeout(() => {
            selected.forEach((select) => {
              if (select.show) this.$emit('selected', this.selectedRows)
            })
          }, 500)
        }
      },
      addComma(val) {
        let sign = ''

        if (val < 0) sign = '-'

        val = val.toString()

        if (val[0] === '0') val = val.substr(1, val.length - 1)

        return val.toString().replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      getUsers() {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (!this.endedList) {
              if (this.$refs.selectActiveCoachesList && this.data.length === 0) {
                this.$refs.selectActiveCoachesList.loadMoreStatus = 'FirstLoad'
              } else if (this.$refs.selectActiveCoachesList && this.data.length > 0) {
                this.$refs.selectActiveCoachesList.loadMoreStatus = 'Loading'
              }

              let times = []
              this.selectedTimes.forEach(item => {
                times.push({
                  time_id: item.timeId,
                  date: item.date
                })
              })

              const payload = {
                lines: times
              }

              getActiveCoaches(payload, this.page, this.filters, this.sorts).then((res) => {
                this.endedList = res.data.data.length === 0
                const coaches = res.data.data
                coaches.forEach((coach) => {
                  this.data.push({
                    row: this.data.length + 1,
                    id: coach.id,
                    registryDate: coach.created_at,
                    gender: coach.user.gender === 1 ? this.$t('genderTypes.man') : this.$t('genderTypes.woman'),
                    phoneNumber: coach.user.phone_number ? coach.user.phone_number : '',
                    firstName: coach.user.name || '',
                    lastName: coach.user.family || '',
                    name: coach.user.name || coach.user.family ? `${coach.user.name || ''} ${coach.user.family || ''}` : this.$t('users.user.labels.unknown'),
                    price: {
                      type: 'price',
                      value: Math.abs(coach.reserve_price) || 0
                    }
                  })
                })

                this.page = res.data.pagination.current_page + 1
                if (res.data.pagination.current_page === 1) {
                  const row_index = this.columnsLabel.map((e) => {
                    return e.field
                  }).indexOf('row')
                  this.columnsLabel[row_index].footer.value = res.data.pagination.total
                }

                if (this.$refs.selectActiveCoachesList) this.$refs.selectActiveCoachesList.loadMoreStatus = ''

                this.requestSent = false
              })
                .catch(() => {
                  if (this.$refs.selectActiveCoachesList) this.$refs.selectActiveCoachesList.loadMoreStatus = 'Danger'

                  this.requestSent = false
                })
            } else {

              this.requestSent = false
            }
          }, 400)
        }
      },
      setFilters(filters) {
        const filters_list = []
        Object.keys(filters).forEach((key) => {
          switch (key) {
            case 'gender':
              if (filters[key].search.value > 0) filters_list.push(`gender=${  filters[key].search.value }`)
              break

            case 'name':
              if (filters[key].search !== '') filters_list.push(`user=${  filters[key].search}`)
              break

            case 'phoneNumber':
              if (filters[key].search !== '') filters_list.push(`phone_number=${  filters[key].search}`)
              break

            case 'contractPrice':
              if (filters[key].search !== '') filters_list.push(`contract_price=${  filters[key].search},${filters[key].type.id}`)
              break

            case 'price':
              if (filters[key].search !== '') filters_list.push(`reserve_price=${  filters[key].search},${filters[key].type.id}`)
              break

            case 'balance':
              if (filters[key].search !== '') filters_list.push(`balance=${  parseInt(filters[key].search) * -1},${filters[key].type.id}`)
              break
          }
        })

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getUsers()
      },
      setSort(columns) {
        const sorts_list = []
        Object.keys(columns).forEach((key) => {
          switch (key) {
            case 'row':
              sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'gender':
              sorts_list.push(`order[0]=gender,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'name':
              sorts_list.push(`order[0]=user,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'phoneNumber':
              sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'contractPrice':
              sorts_list.push(`order[0]=contract_price,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'price':
              sorts_list.push(`order[0]=reserve_price,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'balance':
              sorts_list.push(`order[0]=virtual_balance,${  columns[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) sorts_list.push('order[0]=created_at,desc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getUsers()
      }
    }
  }
</script>

<style scoped>
  .select-active-coach-list {
    height: 100%;
  }
</style>
